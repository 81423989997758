import { useLocation, useMatches, useNavigate } from "react-router-dom";
import styled from "styled-components";
import getPathHeader from "../../functions/getPathHeader";
import { Helmet } from "react-helmet";
import { Paragraph, Title } from "../../components/common/Text";
import * as React from "react";
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { theme } from "../../theme-colors/theme";

const RowContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 20px 0;
  gap: 10px;
`;

const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HorzontalLine = styled.hr`
  background-color: ${(props) => props.theme.btnColor};
  border: none;
  width: 100px;
  height: 3px;
`;

function Header() {
  const location = useLocation();
  const pathHeader = getPathHeader(location.pathname);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{

        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundPosition: "center",
        backgroundColor: theme.lightColor,
        height: "300px",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Helmet>
        <title>{pathHeader}</title>
      </Helmet>
      {
        pathHeader == "ABOUT" ?
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <img
              src="./store/about/about.png"
              alt="Example"
              style={{
                width: "100%", // 이미지를 부모 크기에 맞게 꽉 차게 만듦
                height: "100%", // 이미지를 부모 크기에 맞게 꽉 차게 만듦
                objectFit: "cover", // 이미지가 비율을 유지하면서 부모를 꽉 채우도록 설정
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // 좌우, 위아래 중앙 정렬
                color: "white", // 텍스트 색상
                textAlign: "center", // 텍스트 가운데 정렬
              }}
            >
              <Typography fontSize={20} fontFamily='MazzardM-Bold' align="center">
                ABOUT US
              </Typography>
            </Box>
          </Box>
          :
          <Box>
            <Typography fontSize={20} color='black' fontFamily='MazzardM-Bold' align="center">
              {pathHeader}
            </Typography>
            <Typography fontSize={20} color='black' fontFamily='MazzardM-Regular' >
              Find the Tours That Spark your mind
            </Typography>
          </Box>
      }

    </Container >
  );
}
export default Header;
