import { Box, Divider, Tab, Tabs } from "@mui/material";
import { theme } from "../../../../theme-colors/theme";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React from "react";
import Itinerary from "./ItineraryTab";
import Overview from "./Overview";
import { ITour } from "../../../../api/exploreApi";
import { wrap } from "module";

export default function TourTab(tour: ITour) {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return <Box>
        <TabContext value={value} >
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
                <TabList onChange={handleChange} aria-label="lab API tabs"
                    TabIndicatorProps={{ style: { display: "none" } }}
                    sx={{
                        '& .MuiTab-root': {
                            fontSize: '1rem',
                            fontFamily: 'MazzardM-Medium',
                            textTransform: 'none',
                            color: theme.textColor,
                        },
                        '& .Mui-selected': {
                            fontFamily: 'MazzardM-Bold',
                            fontWeight: 'bold',
                            color: theme.textColor + '!important',
                        },
                    }}>
                    <Tab label="Overview" value="1" />
                    <Tab label="Itinerary" value="2" />
                    {/* <Tab label="Reviews" value="3" />
                    <Tab label="Q&A" value="4" /> */}
                </TabList>
                <Divider
                    sx={{
                        marginY: 1,
                        border: 1,
                        color: theme.grayColor,
                        width: '100%'
                    }}
                />
                <TabPanel sx={{ width: '100%', p: 0 }} value="1">
                    <Overview key={tour.id} {...tour} />
                </TabPanel>
                <TabPanel sx={{ width: '100%', p: 0 }} value="2">
                    <Itinerary itinerary={tour.itinerary} />
                </TabPanel >
                {/* <TabPanel sx={{ width: '100%' }} value="3">Item Three</TabPanel>
                <TabPanel sx={{ width: '100%' }} value="4">Item Four</TabPanel> */}
            </Box>

        </TabContext>
    </Box>
}