import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import styled from "styled-components";

export const ColumnContainer = styled(Container).attrs((props) => ({
  disableGutters: true,
  maxWidth: false,
}))``;

export const ColumnStack = styled(Stack).attrs((props) => ({
  justifyContent: "center",
  alignItems: "center",
  spacing: 2,
  useFlexGap: true,
  flexWrap: "wrap",
}))``;

export const RowStack = styled(Stack).attrs((props) => ({
  justifyContent: "center",
  alignItems: "center",
  spacing: 2,
  useFlexGap: true,
  flexWrap: "wrap",
  direction: "row"
}))``;

export const FlexibleColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 1400px) {
    flex-direction: row;
  }
`;
