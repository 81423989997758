import AWS from 'aws-sdk';
import { send } from 'process';
import { awsConfig } from '../../../aws-config';

AWS.config.update({
    region: awsConfig.region,
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
});

const ses = new AWS.SES();

const sendEmail = async (from: string, subject: string, body: string) => {
    const params = {
        Destination: {
            ToAddresses: ['share@sparkbirdkorea.com'],
        },
        Message: {
            Body: {
                Text: { Data: body },
            },
            Subject: { Data: subject },
        },
        Source: from, // 보내는 이메일 주소
    };

    try {
        await ses.sendEmail(params).promise();
        console.log('Email sent successfully');
    } catch (error) {
        console.error('Error sending email:', error);
    }
};

export default sendEmail;