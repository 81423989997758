import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Stack, Tab, Tabs } from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Typography } from "@mui/material";
import { theme } from "../../../../theme-colors/theme";
import { Itinerary } from "../../../../api/exploreApi";
import { styled } from '@mui/system';

// icon
import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BirdCard from "./BirdCard";


const ArrowButton = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
}));


type ItineraryTabProps = {
  itinerary: Itinerary
};


export default function ItineraryTab({ itinerary }: ItineraryTabProps) {
  const [value, setValue] = React.useState('1');

  const leftArrowColor = parseInt(value) == 1 ? 'lightgray' : 'black';
  const rightArrowColor = parseInt(value) == itinerary.itineraryDays.length ? 'lightgray' : 'black';


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (

    <TabContext value={value} >
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
        <Box justifyContent="space-between" alignItems="center" display='flex' width='100%'
          sx={{
            position: 'sticky', top: 0,
            backgroundColor: 'white', zIndex: 10,
          }}>
          < ArrowButton style={{ color: leftArrowColor }} onClick={() => setValue((prevValue) => {
            if (parseInt(prevValue) - 1 >= 1) {
              return (parseInt(prevValue) - 1).toString()
            } else
              return prevValue;
          })}>
            <KeyboardArrowLeftIcon sx={{ fontSize: 50 }} />
          </ArrowButton>

          <Box sx={{
            flexShrink: 0,
            overflow: 'auto',
            width: { xs: '80%' }
          }}
            display='flex'
            alignContent='center'
            justifyContent='center'>
            <TabList onChange={handleChange} aria-label="lab API tabs"
              TabIndicatorProps={{ style: { display: "none" } }}
              sx={{
                display: 'flex',
                '& .MuiTab-root': {
                  fontFamily: 'MazzardM-Medium',
                  textTransform: 'none',
                  color: theme.textColor,
                },
                '& .Mui-selected': {
                  fontFamily: 'MazzardM-Bold',
                  fontWeight: 'bold',
                  color: theme.textColor + '!important',
                },
              }}>

              {itinerary.itineraryDays.map((data) => (
                <Tab label={"Day " + data.sequence} value={data.sequence.toString()} />
              ))}
            </TabList>
          </Box>


          <ArrowButton style={{ color: rightArrowColor }} onClick={() => setValue((prevValue) => {
            if (parseInt(prevValue) + 1 <= itinerary.itineraryDays.length) {
              return (parseInt(prevValue) + 1).toString()
            } else
              return prevValue;
          })}>
            <KeyboardArrowRightIcon sx={{ fontSize: 50 }} />
          </ArrowButton>
        </Box>

        {
          itinerary.itineraryDays.map((data) => (
            <TabPanel
              sx={{ width: { xs: '100vw', sm: '100%' }, px: { xs: '', md: 0 } }}
              value={data.sequence.toString()} >
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Box sx={{ backgroundColor: 'black', width: '30vw', height: { xs: 50, md: 80 }, color: 'white', }} alignContent='center' justifyContent='center'>
                  <Typography fontFamily="MazzardM-Medium" align="center" sx={{ fontSize: { xs: '14px', md: '18px' } }}>DAY {data.sequence}</Typography>
                </Box>
                <Box sx={{ backgroundColor: '#F9F9F9', width: '70vw', height: { xs: 50, md: 80 }, }} alignContent='center' justifyContent='center'>
                  <Typography fontFamily="MazzardM-Medium" align="left" paddingLeft="5%" sx={{ fontSize: { xs: '14px', md: '18px' } }}>{data.title}</Typography>
                </Box>
              </Stack>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  }
                }}
              >

                {
                  data.schedules.map((day, i) => (
                    <TimelineItem>
                      <TimelineSeparator >
                        {
                          day.type === "attraction" ? (
                            <TimelineDot
                              sx={{
                                width: '30px', height: '30px',
                                padding: 0, borderWidth: 0, boxShadow: 0
                              }}
                            >
                              <img src="./../store/logo_b.png"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }} />
                            </TimelineDot>
                          ) :
                            (
                              <TimelineDot
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  backgroundColor: theme.grayColor,
                                  boxShadow: 'none',
                                  borderWidth: '10px',
                                  borderColor: theme.backgroundColor,
                                  margin: 0,
                                }}
                              />)}
                        {i !== data.schedules.length - 1 ? (
                          <TimelineConnector />
                        ) : null}

                      </TimelineSeparator>
                      <TimelineContent sx={{
                        paddingY: 0,
                        width: { xs: '100%', md: '95%' }
                      }}>
                        {
                          day.type === "attraction" ? (
                            <>
                              <Box height="50px" alignContent="center" justifyContent="center" alignItems='center'>
                                <Typography fontFamily="MazzardM-Medium" sx={{ fontWeight: 600 }}>{day.name}</Typography>
                              </Box>
                              <Box sx={{ border: "2px solid", borderColor: "#D9D9D9", padding: "2%" }}>
                                <Typography sx={{ fontFamily: { xs: "MazzardM-Light", md: "MazzardM-Medium" }, whiteSpace: 'pre-line', textAlign: 'justify', lineHeight: { xs: '1.2', md: '1.5' } }}>
                                  {day.destination}
                                </Typography>
                              </Box>
                              <Box my="20px">
                                <Typography fontFamily="MazzardM-Medium">TARGET BIRDS</Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 1,
                                    py: 1,
                                    marginBottom: 5,
                                    width: "100%",
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    scrollSnapType: "x mandatory",
                                    "& > *": {
                                      scrollSnapAlign: "center",
                                    },

                                  }}
                                >
                                  {day.targetBirds?.map((topBirdId: string) => (
                                    <BirdCard topBirdId={topBirdId} ></BirdCard>
                                  ))}
                                </Box>
                              </Box></>
                          ) :
                            <Box height="30px" alignContent="center">
                              <Typography fontFamily="MazzardM-Medium" sx={{ fontWeight: 600 }}>{day.name}</Typography>
                            </Box>
                        }
                      </TimelineContent>
                    </TimelineItem>
                  ))
                }
              </Timeline>
            </TabPanel>
          ))
        }
      </Box >
    </TabContext >
  );
}
