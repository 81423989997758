import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { HomeHeader } from "./layouts/HomeHeader";
import { createTheme } from "@mui/material";



const GlobalStyle = createGlobalStyle`
  ${reset}

    @font-face {
    font-family: 'MazzardH-Black';
    src: url('/fonts/MazzardH-Black.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardL-Light';
    src: url('/fonts/MazzardL-Light.otf') format('opentype');
    font-weight: lighter;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardH-BlackItalic';
    src: url('/fonts/MazzardH-BlackItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardL-LightItalic';
    src: url('/fonts/MazzardL-LightItalic.otf') format('opentype');
    font-weight: lighter;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardH-Bold';
    src: url('/fonts/MazzardH-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardL-Medium';
    src: url('/fonts/MazzardL-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardH-BoldItalic';
    src: url('/fonts/MazzardH-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardL-MediumItalic';
    src: url('/fonts/MazzardL-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardH-ExtraBold';
    src: url('/fonts/MazzardH-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardL-Regular';
    src: url('/fonts/MazzardL-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardH-ExtraBoldItalic';
    src: url('/fonts/MazzardH-ExtraBoldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardL-SemiBold';
    src: url('/fonts/MazzardL-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardH-ExtraLight';
    src: url('/fonts/MazzardH-ExtraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardL-SemiBoldItalic';
    src: url('/fonts/MazzardL-SemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardH-Italic';
    src: url('/fonts/MazzardH-Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardL-Thin';
    src: url('/fonts/MazzardL-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardL-ThinItalic';
    src: url('/fonts/MazzardL-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardM-Black';
    src: url('/fonts/MazzardM-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardM-BlackItalic';
    src: url('/fonts/MazzardM-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardM-Bold';
    src: url('/fonts/MazzardM-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardM-BoldItalic';
    src: url('/fonts/MazzardM-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardM-ExtraBold';
    src: url('/fonts/MazzardM-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardM-ExtraBoldItalic';
    src: url('/fonts/MazzardM-ExtraBoldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardM-ExtraLight';
    src: url('/fonts/MazzardM-ExtraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardM-ExtraLightItalic';
    src: url('/fonts/MazzardM-ExtraLightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardM-Italic';
    src: url('/fonts/MazzardM-Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardM-Light';
    src: url('/fonts/MazzardM-Light.otf') format('opentype');
    font-weight: lighter;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardM-LightItalic';
    src: url('/fonts/MazzardM-LightItalic.otf') format('opentype');
    font-weight: lighter;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardM-Medium';
    src: url('/fonts/MazzardM-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardM-MediumItalic';
    src: url('/fonts/MazzardM-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardM-Regular';
    src: url('/fonts/MazzardM-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardM-SemiBold';
    src: url('/fonts/MazzardM-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardM-SemiBoldItalic';
    src: url('/fonts/MazzardM-SemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'MazzardM-Thin';
    src: url('/fonts/MazzardM-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'MazzardM-ThinItalic';
    src: url('/fonts/MazzardM-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
  }

  body{
    font-family: "MazzardM-Regular";
    font-weight: 100;
    font-optical-sizing: auto;
    background-color: ${(props) => props.theme.backgroundColor};
    color:${(props) => props.theme.textColor};
  }

  h1, h2, h3, h4, h5, h6, p, div {
    font-family: 'MazzardM-Regular';
  }
  *{
    box-sizing: border-box;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
`;
const theme = createTheme({
  typography: {
    fontFamily: `"MazzardM-Regular" !important`,
  },
});
function Main() {
  return (
    <ThemeProvider theme={theme}>
      <HomeHeader />
    </ThemeProvider>
  );
}

export default Main;
