import { Outlet, useLocation, useNavigate } from "react-router-dom";

import getPathHeader from "../../functions/getPathHeader";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import { theme } from "../../theme-colors/theme";
import NavigationMenu from "../../enum/NavigationMenu";
import Header from "./Header";
import { BorderAll } from "@mui/icons-material";

const languages = [
  'English',
  '한국어'
];


interface NavigationBarProps {
  currentPath: string;
}

export default function NavigationBar({ currentPath }: NavigationBarProps) {
  const navigate = useNavigate();
  const onMainClick = () => {
    navigate("/");
  };
  const location = useLocation();
  const pathHeader = getPathHeader(location.pathname);

  //hover event
  const [isHovered, setIsHovered] = React.useState(false);
  const isActive = (pathHeader !== "Spark Bird Tour" || isHovered);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [personName, setPersonName] = React.useState<string[]>([]);
  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <AppBar
      position="static"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        backgroundColor: isActive ? theme.backgroundColor : 'transparent',
        p: 0,
        boxShadow: 0,
        borderBottom: 1,
        borderColor: isActive ? '#D9D9D9' : 'transparent',
        transition: 'background-color 0.3s, color 0.3s'
      }}
    >
      <Toolbar sx={{
        display: "flex",
        justifyContent: "flex-start",
        borderBottom: 1,
        py: '0 !important',
        px: '10% !important',
        borderColor: isActive ? '#D9D9D9' : 'transparent',
      }}>
        {/* 모바일 메인 아이콘 */}
        <Box
          onClick={onMainClick}
          sx={{
            justifyContent: "center",
            flex: "1",
            visibility: {
              xs: 'visible',
              sm: 'hidden'
            },
            display: {
              xs: 'flex',
              sm: 'none'
            },
            height: "40px",
            width: "40px",
            cursor: "pointer",
            marginRight: "50px"
          }}
        >
          <img src={isActive ? `/store/logo_b.png` : `/store/logobig_w.png`} />
        </Box>
        <Box
          sx={{
            flex: "1",
            justifyContent: "flex-start",
            display: { xs: "flex", sm: "flex" },
          }}
        >
          {NavigationMenu.map((item) => (
            <Button
              key={item}
              sx={{
                color: isActive ? theme.textColor : theme.backgroundColor,
                fontFamily: 'MazzardM-Regular'
              }}
              onClick={() => {
                navigate(`/${item}`);
              }}
            >
              {item}
            </Button>
          ))}
        </Box>
        <Box
          onClick={onMainClick}
          sx={{
            justifyContent: "center",
            flex: "1",
            display: "flex",
            visibility: {
              xs: 'hidden',
              sm: 'visible'
            },
            height: "90px",
            my: "20px",
            cursor: "pointer"
          }}
        >
          <img src={isActive ? `/store/logobig_b.png` : `/store/logo_w.png`} />
        </Box>

        {/* 공갈 */}
        <Box
          sx={{
            flex: "1",
          }}
        >

        </Box>

        {/* 언어, 검색 버튼 */}
        {/* <Box
          sx={{
            flexBasis: 0,
            flexGrow: 1, justifyContent: "center",
            display: { xs: "none", sm: "flex" },
            gap: 4
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <img
              src={isActive ? `/store/language.png` : `/store/language_w.png`}
              alt="SparkBird"
              style={{ width: "25px", height: "25px" }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <img
              src={isActive ? `/store/search.png` : `/store/search_w.png`}
              key={location.state}
              style={{ width: "25px", height: "25px" }}
            />
          </Box>
        </Box> */}
      </Toolbar>
      {pathHeader === "Spark Bird Tour" ? "" : <Header />}
      {/* </Container> */}
    </AppBar >
  );
}
