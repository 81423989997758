import React, { useState, useEffect } from 'react';
import { useQuery } from "@tanstack/react-query";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/system';
import { IMain, getAllMains } from "../../api/exploreApi";
import { readImageFromS3 } from "../../functions/readImageFromS3";
import { theme } from '../../theme-colors/theme';

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  color: 'white',
  backgroundColor: 'transparent',
  width: 48,
  height: 48,
  zIndex: 10,
  '&:hover': {
    backgroundColor: 'transparent',
    transform: 'translateY(-50%) scale(1.5)',
  },
}));

const LeftArrowButton = styled(ArrowButton)({
  left: '10%',
});

const RightArrowButton = styled(ArrowButton)({
  right: '10%',
});


const ImageWrapper = styled('div')<{ direction: 'next' | 'prev' }>(({ direction }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animation: direction === 'next' ? 'swipe-in-next 0.3s ease forwards' : 'swipe-in-prev 0.3s ease forwards',
  '@keyframes swipe-in-next': {
    from: { transform: 'translateX(50%)' },
    to: { transform: 'translateX(0)' },
  },
  '@keyframes swipe-in-prev': {
    from: { transform: 'translateX(-50%)' },
    to: { transform: 'translateX(0)' },
  },
}));

const CardImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export function HomeHeader() {
  const [activeStep, setActiveStep] = useState(0);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [direction, setDirection] = useState<'next' | 'prev'>('next'); // 방향 상태 추가
  const { innerHeight: height } = window;

  const { isLoading: mainLoading, data: images } = useQuery<IMain[]>({
    queryKey: ["mains"],
    queryFn: () => getAllMains(),
  });

  const [maxSteps, setMaxSteps] = useState(0);

  useEffect(() => {
    if (images) {
      setMaxSteps(images.length);
      // 모든 이미지를 미리 로드
      const loadImages = async () => {
        const urls = await Promise.all(images.map(image => readImageFromS3(image.url)));
        setImageUrls(urls);
      };
      loadImages();
    }
  }, [images]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [maxSteps]);

  const handleNext = () => {
    setDirection('next');
    setActiveStep((prevActiveStep) =>
      prevActiveStep === maxSteps - 1 ? 0 : prevActiveStep + 1
    );
  };

  const handleBack = () => {
    setDirection('prev');
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  };

  if (mainLoading || !images) {
    return (
      <Helmet>
        <title>SparkBird Korea</title>
      </Helmet>
    );
  }

  return (
    <>
      <Helmet>
        <title>SparkBird Korea</title>
      </Helmet>
      <Card
        component="li"
        variant="solid"
        sx={{
          height: height,
          display: "flex",
          flexDirection: "column",
          minWidth: 300,
          flexGrow: 1,
          "--Card-radius": "0px",
          mt: '-130px',
          zIndex: -1
        }}
      >
        <CardCover>
          <ImageWrapper key={activeStep} direction={direction}>
            <CardImage src={imageUrls[activeStep]} alt={`Slide ${activeStep}`} />
          </ImageWrapper>
        </CardCover>

        <CardContent sx={{ alignItems: "center" }}>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "100px",
              // bgcolor: "rgba(0, 0, 0, 0.34)",
              color: "white",
              px: "10%",
              alignItems: "center",
            }}
          >
            <Typography
              level="body-md"
              fontSize={25}
              sx={{ color: theme.lightColor }}
              fontFamily='Mazzard'
            >
              {images[activeStep].title}
            </Typography>
            <Typography
              level="body-xs"
              fontSize={15}
              sx={{ color: theme.lightColor }}
              fontFamily='Mazzard'
            >
              {images[activeStep].description}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <LeftArrowButton onClick={handleBack}>
        <KeyboardArrowLeftIcon sx={{ fontSize: 50 }} />
      </LeftArrowButton>
      <RightArrowButton onClick={handleNext}>
        <KeyboardArrowRightIcon sx={{ fontSize: 50 }} />
      </RightArrowButton>
    </>
  );
}
