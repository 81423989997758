import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { readImageFromS3 } from "../../functions/readImageFromS3";
import { useQuery } from "@tanstack/react-query";
import { getTopBirdById } from "../../api/exploreApi";
import BirdCard from "../pages/tour/components/BirdCard";
import { myMY } from "@mui/material/locale";

function About() {
  const { isLoading: birdLoading, data: topBirdData } = useQuery<string[]>({
    queryKey: ["topbirds"],
    queryFn: () => getTopBirdById('7462a4b5-33ab-4c0e-8399-5246604b8a4a'),
  });
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Box width='100vw' >
        <Typography sx={{
          fontSize: {
            xs: "16px",
            sm: "20px",
            md: "28px",
            lg: "40px",
            xl: "52px",
          }
        }} align="center" fontFamily="MazzardM-Bold" my={5}>
          FIND YOUR SPARK BIRD IN KOREA
        </Typography>
        <Typography sx={{ fontSize: { xs: "8px", sm: "12px", md: "16px", lg: "20px", xl: "26px" } }} align="center" fontFamily="MazzardM-Regular" mb={5}>
          Leading birdwatching tour company in Korea
        </Typography>
        <Typography sx={{ fontSize: { xs: "8px", sm: "12px", md: "16px", lg: "20px", xl: "26px" } }} align="center" fontFamily="MazzardM-Regular" mx='30%'>
          SparkBird Korea is the premier birdwatching tour company in Korea, dedicated to those who love nature and birds.
          <br />
          Our name is inspired by the term 'Spark bird,' which refers to the bird that first ignited a person's interest in birdwatching.
          <br />
          We aim to be your companion on your first steps into the fascinating world of birdwatching in Korea.
        </Typography>
      </Box >
      {/* ========= 가이드 프로필 ========= */}

      < Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }} my={10}>
        <ProfileCard />
        <ProfileCard />
        <ProfileCard />
      </Box >
      <Box>
        <Divider
          sx={{
            border: 1,
            color: '#D9D9D9',
            mx: 20
          }}
        />
        <Typography sx={{ fontSize: { xs: "11px", sm: "17px", md: "22px", lg: "28px", xl: "36px" } }} align="center" fontFamily="MazzardM-Bold" my={10}>
          WHY CHOOSE SPARKBIRD KOREA?
        </Typography>

        {/* ====================== */}
        <Why title="EXPERT LOCAL GUIDES" url="./store/about/expert.png"
          description="Our guides are seasoned professionals with an in-depth understanding of Korea’s unique bird species and natural habitats. They’ll take you to the most breathtaking locations at the perfect times. With multilingual skills, including English and Mandarin, we ensure seamless communication throughout your journey." />

        {/* ====================== */}
        <Why title="SUSTAINABILITY MATTERS" url="./store/about/sustainability.png"
          description=" We are committed to protecting Korea’s stunning natural environments. Our tours prioritize eco-friendly practices and include educational activities to promote awareness of Korea’s unique ecosystems." />

        {/* ====================== */}
        <Why title="CULTURAL IMMERSION" url="./store/about/cultural.png"
          description="Discover more than just birds. After a day of exploration, experience authentic Korean culture through traditional meals, craft workshops, or visits to historic landmarks that connect nature with Korea’s rich heritage." />

        {/* ====================== */}
        <Why title="HASSLE-FREE TRAVEL" url="./store/about/hassle.png"
          description="We handle the details so you can focus on enjoying your adventure. From visa support and accommodation to pick-up and transportation, we ensure every part of your trip is seamless." />


      </Box>

    </Box >

  );
  type WhyProps = {
    title: string;
    description: string;
    url: string;
  };
  function Why({ title, description, url }: WhyProps) {
    return <Box
      display='flex'
      flexDirection='row'
      mx={20}
      my={5}
    >
      <img
        src={url}
        alt="Example"
        style={{
          minWidth: "200px",
          // minHeight: "200px",
          width: "30%",
          height: "auto",
          objectFit: "cover",
          marginRight: 20
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Typography sx={{ fontSize: { xs: "9px", sm: "14px", md: "19px", lg: "23px", xl: "30px" } }} align="left" fontFamily="MazzardM-Bold" mb={5}>
          {title}
        </Typography>
        <Typography sx={{ fontSize: { xs: "8px", sm: "12px", md: "16px", lg: "20px", xl: "26px" } }} align="left" fontFamily="MazzardM-Regular">
          {description}
        </Typography>
      </Box>
    </Box>
  }


  function ProfileCard() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: '13vw',
          minWidth: '130px',
          padding: 2,
        }}
      >
        {/* 동그란 얼굴 사진 */}
        <Avatar
          alt="Person Name"
          src="https://via.placeholder.com/150" // 여기에 실제 이미지 URL을 넣으세요.
          sx={{
            width: '10vw',
            height: '10vw',
            minWidth: '100px',
            minHeight: '100px',
            marginBottom: 2,
          }}
        />

        {/* 사람 이름 */}
        <Typography sx={{ fontSize: { xs: "8px", sm: "12px", md: "16px", lg: "20px", xl: "26px" } }} fontFamily="MazzardM-Medium"  >
          DaeHwan Kim
        </Typography>

        {/* 사람 설명 */}
        <Typography sx={{ fontSize: { xs: "8px", sm: "12px", md: "16px", lg: "20px", xl: "26px" } }} fontFamily="MazzardM-Regular" color="gray" >
          Birder & Guide
        </Typography>
      </Box >
    );
  }

}

export default About;
